import hantuSenior from 'src/sections/manage/component/turnover/hantu-senior-list';

export default {
  csrf: '/sanctum/csrf-cookie',
  auth: {
    me: '/api/me',
    qrcode: (id?: any) => `/api/me/uuid/${!id ? '' : String(id)}`,
    generateQrcode: (id?: any) => `/api/me/uuid/generate/${!id ? '' : String(id)}`,
    login: '/api/login',
    logout: '/api/logout',
    permissions: 'api/me/permissions',
    changePin: 'api/user/agent/change/pin',
  },
  setting: {
    zone: '/api/setting/zone',
    onlineTimeAll: '/api/online/time/all',
    userTime: '/api/user/result/setting',
    userLimit: '/api/user/limit/setting',
    userLimitCreate: '/api/user/limit/setting/create',
    userTimeCreate: '/api/user/result/setting/create',
    gameType: '/api/online/time/game/type',
    resultTime: '/api/setting/result/times',
    resultLottery5d: '/api/setting/result/lottery/5d',
    updateHoliday: '/api/setting/result/times/holiday',
    hantuList: '/api/setting/hantu/list',
    hantuUpdate: '/api/setting/hantu/update',
    hantuSenior: '/api/setting/hantu/seniors',
    hantuSeniorCreate: `/api/setting/hantu/seniors/create`,
    hantuSeniorDelete: `/api/setting/hantu/seniors/delete`,
  },
  time: {
    filter: '/api/online/time/filter',
    playing: '/api/online/time/playing',
    gameType: '/api/online/time/game/type',
    transfer: '/api/online/v2/ticket/transfer',
    transferTicket: '/api/online/v2/ticket/transfer/list',
  },
  result: {
    root: (id: any) => `/api/result/${String(id)}`,
    time: '/api/result/time',
    getResult: '/api/result/get',
    pushResult: '/api/result/push',
    guess: '/api/result/guess',
    release: '/api/result/release',
    releaseVn: '/api/result/release/vn',
    release5d: '/api/result/release/5d',
    releaseJackpot: '/api/result/release/jackpot',
    generate: (id: any) => `/api/result/generate/${String(id)}`,
    calculate: (id: any) => `/api/online/result/calculate/${String(id)}`,
  },
  ticket: {
    load: '/api/ticket/load',
    bet: '/api/ticket/bet',
    edit: '/api/ticket/edit',
    save: '/api/ticket/save',
    delete: '/api/ticket/delete',
    forceDelete: '/api/ticket/delete/force',
    editForm: '/api/ticket/edit/form',
    ticketNumberTop: '/api/ticket/number/top',
    addBreakNumber: '/api/ticket/breaknumber/add',
    voidTicketNumberTop: '/api/ticket/number/void',
    loadBreakNumber: '/api/ticket/breaknumber/load',
    changeTime: '/api/ticket/change/time',
    recalculate: '/api/result/recalculate',
    overLimit: '/api/report/ticket/overlimit',
    overLimitTotal: '/api/report/ticket/overlimit/total',
  },
  credit: {
    creditDetail: (id: string) => `/api/online/credit/detail/${String(id)}`,
    transferLog: `/api/online/credit/logs`,
    withdraw: '/api/online/credit/transfer/withdraw',
  },
  report: {
    cash: '/api/report/agent/cash',
    dailyBet: '/api/report/daily/bet',
    dailyDetail: '/api/report/daily/detail',
    dailyAgent: '/api/report/agent/daily',
    dailyAgentTicket: '/api/report/agent/ticket',
    agentGameTicket: '/api/report/game/agent/ticket',
    agentGameTicketTotal: '/api/report/game/agent/ticket/total',
    gameAgent: '/api/report/game/agent',
    gameAgentProfit: '/api/report/game/agent/profit',
    gameAgentProfitTotal: '/api/report/game/agent/profit/total',
    gameAgentTotal: '/api/report/game/agent/total',
    dailyAgentV1: '/api/report/agent/daily/v1',
    dailyAgentTotal: '/api/report/agent/daily/total',
    agentDetail: (id: string) => `/api/report/agent/detail/${String(id)}`,
    agentDetailTotal: (id: string) => `/api/report/agent/detail/total/${String(id)}`,
    exportDailyBet: '/api/report/daily/bet/export',
    dailyBetOverLimit: '/api/report/daily/bet/overlimit',
    exportDailyDetail: '/api/report/daily/detail/export',
    dailyDealer: '/api/report/daily/dealer',
    dealerSummary: '/api/report/daily/summary',
  },
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  role: {
    get: '/api/role/get',
  },
  transaction: {
    deposit: '/api/transaction/deposit',
    withdraw: '/api/transaction/withdraw',
  },

  user: {
    children: '/api/user/children',
    userTransfer: '/api/user/transfer',
    userInfo: '/api/user/info',
    admin: '/api/user/admin',
    resetPassword: '/api/user/admin/reset-password',
    dealer: '/api/user/dealer',
    updatePassword: '/api/user/password/update',
    agent: '/api/user/agent',
  },
  location: 'api/location',
};
